import React, { Fragment, useEffect, useState } from "react";

import {
  Alert,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

// hooks
import { useProfile } from "../../Components/Hooks/UserHooks";

// redux toolkit
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createPayAgreement } from "../../slices/apps/agreementSlice";
import { fetchCustomer } from "../../slices/apps/customerSlice";
import { findBin } from "../../slices/apps/paymentSlice";
import { fetchBulkPackageCount } from "../../slices/apps/packageSlice";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// react select
import Select from "react-select";

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

// diğer importlar
import ReactInputMask from "react-input-mask";

// toaster
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data dosyaları
import CityJson from "../../common/data/city.json";

// router
import { useNavigate } from "react-router-dom";

const city = CityJson.map((data) => ({
  label: data.il_adi,
  value: data.il_adi,
  districts: data.ilceler
    .map((ilce) => ({
      label: ilce.ilce_adi,
      value: ilce.ilce_adi,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)),
})).sort((a, b) => a.label.localeCompare(b.label));

const carsInfo = [
  {
    typeId: 1,
    typeName: "Otomobil",
    brands: [
      { brandId: 1, brandName: "ALFA ROMEO" },
      { brandId: 2, brandName: "ASTON MARTIN" },
      { brandId: 3, brandName: "AUDI" },
      { brandId: 4, brandName: "SAAB" },
      { brandId: 5, brandName: "SEAT" },
      { brandId: 6, brandName: "BMW" },
      { brandId: 7, brandName: "BUICK" },
      { brandId: 8, brandName: "BMC" },
      { brandId: 9, brandName: "DAEWOO" },
      { brandId: 10, brandName: "CADILLAC" },
      { brandId: 11, brandName: "CHEVROLET" },
      { brandId: 12, brandName: "CHRYSLER" },
      { brandId: 13, brandName: "CITROEN" },
      { brandId: 14, brandName: "DAF" },
      { brandId: 15, brandName: "DAIHATSU" },
      { brandId: 16, brandName: "DODGE/USA" },
      { brandId: 17, brandName: "FERRARI" },
      { brandId: 18, brandName: "FIAT" },
      { brandId: 19, brandName: "FORD/OTOSAN" },
      { brandId: 20, brandName: "HONDA" },
      { brandId: 21, brandName: "ISUZU" },
      { brandId: 22, brandName: "LANCIA" },
      { brandId: 23, brandName: "LINCOLN" },
      { brandId: 24, brandName: "LADA" },
      { brandId: 25, brandName: "MASERATI" },
      { brandId: 26, brandName: "MAZDA" },
      { brandId: 27, brandName: "MERCEDES" },
      { brandId: 28, brandName: "MERCURY" },
      { brandId: 29, brandName: "MITSUBISHI" },
      { brandId: 30, brandName: "TOFAS-FIAT" },
      { brandId: 31, brandName: "OTOKAR/MAGIRUS" },
      { brandId: 32, brandName: "NISSAN" },
      { brandId: 33, brandName: "OPEL" },
      { brandId: 34, brandName: "PEUGEOT" },
      { brandId: 35, brandName: "PONTIAC" },
      { brandId: 36, brandName: "PORSCHE" },
      { brandId: 37, brandName: "TATA" },
      { brandId: 38, brandName: "RENAULT (OYAK)" },
      { brandId: 39, brandName: "RENAULT" },
      { brandId: 40, brandName: "RANGE ROVER" },
      { brandId: 41, brandName: "SSANGYONG" },
      { brandId: 42, brandName: "SUZUKI" },
      { brandId: 43, brandName: "SKODA" },
      { brandId: 44, brandName: "SUBARU" },
      { brandId: 45, brandName: "SCANIA" },
      { brandId: 46, brandName: "TOYOTA" },
      { brandId: 47, brandName: "VOLKSWAGEN" },
      { brandId: 48, brandName: "VOLVO" },
      { brandId: 49, brandName: "FORD /USA" },
      { brandId: 50, brandName: "HYUNDAI" },
      { brandId: 51, brandName: "JAGUAR" },
      { brandId: 52, brandName: "JAC" },
      { brandId: 53, brandName: "GULERYUZ" },
      { brandId: 54, brandName: "LAMBORGHINI" },
      { brandId: 55, brandName: "LOTUS" },
      { brandId: 56, brandName: "MORGAN" },
      { brandId: 57, brandName: "PROTON" },
      { brandId: 58, brandName: "MAN" },
      { brandId: 59, brandName: "ZIRAI TRAKTOR" },
      { brandId: 60, brandName: "VOLVO-TR" },
      { brandId: 61, brandName: "LAND ROVER" },
      { brandId: 62, brandName: "MINI" },
      { brandId: 63, brandName: "NEOPLAN" },
      { brandId: 64, brandName: "DACIA" },
      { brandId: 65, brandName: "SETRA" },
      { brandId: 66, brandName: "SMART" },
      { brandId: 67, brandName: "CATERHAM" },
      { brandId: 68, brandName: "INFINITI" },
      { brandId: 69, brandName: "TESLA" },
      { brandId: 70, brandName: "KTM" },
      { brandId: 71, brandName: "CHANGAN" },
      { brandId: 72, brandName: "FISKER" },
      { brandId: 73, brandName: "SHUANGHUAN" },
      { brandId: 74, brandName: "TCV" },
      { brandId: 75, brandName: "OTOYOLIVECOFIAT" },
      { brandId: 76, brandName: "TURKKAR" },
      { brandId: 77, brandName: "TEZELLER" },
      { brandId: 78, brandName: "TEMSA" },
      { brandId: 79, brandName: "ASKAM/FARGO/DESOTO" },
      { brandId: 80, brandName: "HUMMER" },
      { brandId: 81, brandName: "AR-BUS" },
      { brandId: 82, brandName: "IKCO" },
      { brandId: 83, brandName: "BENTLEY" },
      { brandId: 84, brandName: "KARSAN" },
      { brandId: 85, brandName: "PIAGGIO" },
      { brandId: 86, brandName: "HUANGHAI" },
      { brandId: 87, brandName: "VISEON" },
      { brandId: 88, brandName: "KENWORTH" },
      { brandId: 89, brandName: "IRIZAR" },
      { brandId: 90, brandName: "SAIPA" },
      { brandId: 91, brandName: "LEXUS" },
      { brandId: 92, brandName: "SOLARIS" },
      { brandId: 93, brandName: "BOZANKAYA" },
      { brandId: 94, brandName: "MULTICAR" },
      { brandId: 95, brandName: "ADRIA" },
      { brandId: 96, brandName: "HAIMA" },
      { brandId: 97, brandName: "MILLER" },
      { brandId: 98, brandName: "SCHMIDT" },
      { brandId: 99, brandName: "RAVON" },
      { brandId: 100, brandName: "EMT" },
      { brandId: 101, brandName: "ZOTYE" },
      { brandId: 102, brandName: "PILOTCAR" },
      { brandId: 103, brandName: "MCLAREN" },
      { brandId: 104, brandName: "ALPINE" },
      { brandId: 105, brandName: "MG" },
      { brandId: 106, brandName: "XEV" },
      { brandId: 107, brandName: "SERES" },
      { brandId: 108, brandName: "CUPRA" },
      { brandId: 109, brandName: "DONG FENG" },
      { brandId: 110, brandName: "TRUMPCHI/GAC" },
      { brandId: 111, brandName: "ORA" },
      { brandId: 112, brandName: "BRILLIANCE" },
      { brandId: 113, brandName: "MOTORSIKLET" },
      { brandId: 114, brandName: "ALKE" },
      { brandId: 115, brandName: "MEGA" },
      { brandId: 116, brandName: "AIXAM" },
      { brandId: 117, brandName: "FOLKVAN" },
      { brandId: 118, brandName: "PGO" },
      { brandId: 119, brandName: "MJT" },
      { brandId: 120, brandName: "SCAM" },
      { brandId: 121, brandName: "DFM" },
      { brandId: 122, brandName: "HFKANUNI" },
      { brandId: 123, brandName: "MAHINDRA" },
      { brandId: 124, brandName: "CHERY" },
      { brandId: 125, brandName: "TURKAR" },
      { brandId: 126, brandName: "KOENIGSEGG" },
      { brandId: 127, brandName: "GONOW" },
      { brandId: 128, brandName: "ASTRA" },
      { brandId: 129, brandName: "AVIA" },
      { brandId: 130, brandName: "VEICOLI" },
      { brandId: 131, brandName: "SINOTRUK" },
      { brandId: 132, brandName: "BREDAMENARIBUS" },
      { brandId: 133, brandName: "MAYBACH" },
      { brandId: 134, brandName: "PETERBILT" },
      { brandId: 135, brandName: "WIESMANN" },
      { brandId: 136, brandName: "HYMER" },
      { brandId: 137, brandName: "ROLLS-ROYCE" },
      { brandId: 138, brandName: "AKIA" },
      { brandId: 139, brandName: "THE LONDON TAXI" },
      { brandId: 140, brandName: "GREAT WALL" },
      { brandId: 141, brandName: "GROVE" },
      { brandId: 142, brandName: "HISCAR" },
      { brandId: 143, brandName: "BYD" },
      { brandId: 144, brandName: "ZOOMLION" },
      { brandId: 145, brandName: "CODA" },
      { brandId: 146, brandName: "TATRA" },
      { brandId: 147, brandName: "TADANO FAUN" },
      { brandId: 148, brandName: "MENARINIBUS" },
      { brandId: 149, brandName: "PIMAKINA" },
      { brandId: 150, brandName: "DS" },
      { brandId: 151, brandName: "MAZ" },
      { brandId: 152, brandName: "SKYWELL" },
      { brandId: 153, brandName: "KIA" },
      { brandId: 154, brandName: "BUGATTI" },
      { brandId: 155, brandName: "GEELY" },
      { brandId: 156, brandName: "FAW" },
      { brandId: 157, brandName: "ISOBUS" },
      { brandId: 158, brandName: "DFSK" },
      { brandId: 159, brandName: "GMC" },
      { brandId: 160, brandName: "GAZ" },
      { brandId: 161, brandName: "TOG" },
    ],
  },
  {
    typeId: 2,
    typeName: "4 Teker Hafif Ticari",
    brands: [
      { brandId: 1, brandName: "ALFA ROMEO" },
      { brandId: 2, brandName: "ASTON MARTIN" },
      { brandId: 3, brandName: "AUDI" },
      { brandId: 4, brandName: "SAAB" },
      { brandId: 5, brandName: "SEAT" },
      { brandId: 6, brandName: "BMW" },
      { brandId: 7, brandName: "BUICK" },
      { brandId: 8, brandName: "BMC" },
      { brandId: 9, brandName: "DAEWOO" },
      { brandId: 10, brandName: "CADILLAC" },
      { brandId: 11, brandName: "CHEVROLET" },
      { brandId: 12, brandName: "CHRYSLER" },
      { brandId: 13, brandName: "CITROEN" },
      { brandId: 14, brandName: "DAF" },
      { brandId: 15, brandName: "DAIHATSU" },
      { brandId: 16, brandName: "DODGE/USA" },
      { brandId: 17, brandName: "FERRARI" },
      { brandId: 18, brandName: "FIAT" },
      { brandId: 19, brandName: "FORD/OTOSAN" },
      { brandId: 20, brandName: "HONDA" },
      { brandId: 21, brandName: "ISUZU" },
      { brandId: 22, brandName: "LANCIA" },
      { brandId: 23, brandName: "LINCOLN" },
      { brandId: 24, brandName: "LADA" },
      { brandId: 25, brandName: "MASERATI" },
      { brandId: 26, brandName: "MAZDA" },
      { brandId: 27, brandName: "MERCEDES" },
      { brandId: 28, brandName: "MERCURY" },
      { brandId: 29, brandName: "MITSUBISHI" },
      { brandId: 30, brandName: "TOFAS-FIAT" },
      { brandId: 31, brandName: "OTOKAR/MAGIRUS" },
      { brandId: 32, brandName: "NISSAN" },
      { brandId: 33, brandName: "OPEL" },
      { brandId: 34, brandName: "PEUGEOT" },
      { brandId: 35, brandName: "PONTIAC" },
      { brandId: 36, brandName: "PORSCHE" },
      { brandId: 37, brandName: "TATA" },
      { brandId: 38, brandName: "RENAULT (OYAK)" },
      { brandId: 39, brandName: "RENAULT" },
      { brandId: 40, brandName: "RANGE ROVER" },
      { brandId: 41, brandName: "SSANGYONG" },
      { brandId: 42, brandName: "SUZUKI" },
      { brandId: 43, brandName: "SKODA" },
      { brandId: 44, brandName: "SUBARU" },
      { brandId: 45, brandName: "SCANIA" },
      { brandId: 46, brandName: "TOYOTA" },
      { brandId: 47, brandName: "VOLKSWAGEN" },
      { brandId: 48, brandName: "VOLVO" },
      { brandId: 49, brandName: "FORD /USA" },
      { brandId: 50, brandName: "HYUNDAI" },
      { brandId: 51, brandName: "JAGUAR" },
      { brandId: 52, brandName: "JAC" },
      { brandId: 53, brandName: "GULERYUZ" },
      { brandId: 54, brandName: "LAMBORGHINI" },
      { brandId: 55, brandName: "LOTUS" },
      { brandId: 56, brandName: "MORGAN" },
      { brandId: 57, brandName: "PROTON" },
      { brandId: 58, brandName: "MAN" },
      { brandId: 59, brandName: "ZIRAI TRAKTOR" },
      { brandId: 60, brandName: "VOLVO-TR" },
      { brandId: 61, brandName: "LAND ROVER" },
      { brandId: 62, brandName: "MINI" },
      { brandId: 63, brandName: "NEOPLAN" },
      { brandId: 64, brandName: "DACIA" },
      { brandId: 65, brandName: "SETRA" },
      { brandId: 66, brandName: "SMART" },
      { brandId: 67, brandName: "CATERHAM" },
      { brandId: 68, brandName: "INFINITI" },
      { brandId: 69, brandName: "TESLA" },
      { brandId: 70, brandName: "KTM" },
      { brandId: 71, brandName: "CHANGAN" },
      { brandId: 72, brandName: "FISKER" },
      { brandId: 73, brandName: "SHUANGHUAN" },
      { brandId: 74, brandName: "TCV" },
      { brandId: 75, brandName: "OTOYOLIVECOFIAT" },
      { brandId: 76, brandName: "TURKKAR" },
      { brandId: 77, brandName: "TEZELLER" },
      { brandId: 78, brandName: "TEMSA" },
      { brandId: 79, brandName: "ASKAM/FARGO/DESOTO" },
      { brandId: 80, brandName: "HUMMER" },
      { brandId: 81, brandName: "AR-BUS" },
      { brandId: 82, brandName: "IKCO" },
      { brandId: 83, brandName: "BENTLEY" },
      { brandId: 84, brandName: "KARSAN" },
      { brandId: 85, brandName: "PIAGGIO" },
      { brandId: 86, brandName: "HUANGHAI" },
      { brandId: 87, brandName: "VISEON" },
      { brandId: 88, brandName: "KENWORTH" },
      { brandId: 89, brandName: "IRIZAR" },
      { brandId: 90, brandName: "SAIPA" },
      { brandId: 91, brandName: "LEXUS" },
      { brandId: 92, brandName: "SOLARIS" },
      { brandId: 93, brandName: "BOZANKAYA" },
      { brandId: 94, brandName: "MULTICAR" },
      { brandId: 95, brandName: "ADRIA" },
      { brandId: 96, brandName: "HAIMA" },
      { brandId: 97, brandName: "MILLER" },
      { brandId: 98, brandName: "SCHMIDT" },
      { brandId: 99, brandName: "RAVON" },
      { brandId: 100, brandName: "EMT" },
      { brandId: 101, brandName: "ZOTYE" },
      { brandId: 102, brandName: "PILOTCAR" },
      { brandId: 103, brandName: "MCLAREN" },
      { brandId: 104, brandName: "ALPINE" },
      { brandId: 105, brandName: "MG" },
      { brandId: 106, brandName: "XEV" },
      { brandId: 107, brandName: "SERES" },
      { brandId: 108, brandName: "CUPRA" },
      { brandId: 109, brandName: "DONG FENG" },
      { brandId: 110, brandName: "TRUMPCHI/GAC" },
      { brandId: 111, brandName: "ORA" },
      { brandId: 112, brandName: "BRILLIANCE" },
      { brandId: 113, brandName: "MOTORSIKLET" },
      { brandId: 114, brandName: "ALKE" },
      { brandId: 115, brandName: "MEGA" },
      { brandId: 116, brandName: "AIXAM" },
      { brandId: 117, brandName: "FOLKVAN" },
      { brandId: 118, brandName: "PGO" },
      { brandId: 119, brandName: "MJT" },
      { brandId: 120, brandName: "SCAM" },
      { brandId: 121, brandName: "DFM" },
      { brandId: 122, brandName: "HFKANUNI" },
      { brandId: 123, brandName: "MAHINDRA" },
      { brandId: 124, brandName: "CHERY" },
      { brandId: 125, brandName: "TURKAR" },
      { brandId: 126, brandName: "KOENIGSEGG" },
      { brandId: 127, brandName: "GONOW" },
      { brandId: 128, brandName: "ASTRA" },
      { brandId: 129, brandName: "AVIA" },
      { brandId: 130, brandName: "VEICOLI" },
      { brandId: 131, brandName: "SINOTRUK" },
      { brandId: 132, brandName: "BREDAMENARIBUS" },
      { brandId: 133, brandName: "MAYBACH" },
      { brandId: 134, brandName: "PETERBILT" },
      { brandId: 135, brandName: "WIESMANN" },
      { brandId: 136, brandName: "HYMER" },
      { brandId: 137, brandName: "ROLLS-ROYCE" },
      { brandId: 138, brandName: "AKIA" },
      { brandId: 139, brandName: "THE LONDON TAXI" },
      { brandId: 140, brandName: "GREAT WALL" },
      { brandId: 141, brandName: "GROVE" },
      { brandId: 142, brandName: "HISCAR" },
      { brandId: 143, brandName: "BYD" },
      { brandId: 144, brandName: "ZOOMLION" },
      { brandId: 145, brandName: "CODA" },
      { brandId: 146, brandName: "TATRA" },
      { brandId: 147, brandName: "TADANO FAUN" },
      { brandId: 148, brandName: "MENARINIBUS" },
      { brandId: 149, brandName: "PIMAKINA" },
      { brandId: 150, brandName: "DS" },
      { brandId: 151, brandName: "MAZ" },
      { brandId: 152, brandName: "SKYWELL" },
      { brandId: 153, brandName: "KIA" },
      { brandId: 154, brandName: "BUGATTI" },
      { brandId: 155, brandName: "GEELY" },
      { brandId: 156, brandName: "FAW" },
      { brandId: 157, brandName: "ISOBUS" },
      { brandId: 158, brandName: "DFSK" },
      { brandId: 159, brandName: "GMC" },
      { brandId: 160, brandName: "GAZ" },
      { brandId: 161, brandName: "TOG" },
    ],
  },
];

// regex, değişkenler
const phoneRegExp =
  /^(?:[1-9][0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}|[1-9][0-9]{9})$/;

const Checkout = ({ bulkSaleId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile } = useProfile();

  // states
  const { packageState, bulkPackageCount, customers, cardInfo } = useSelector(
    (state) => {
      return {
        packageState: state.package.package,
        bulkPackageCount: state.package.bulkPackageCount,
        customers: state.customer.customers,
        cardInfo: state.payment.cardInfo,
      };
    },
    shallowEqual
  );

  const fetchData = async () => {
    try {
      await dispatch(fetchBulkPackageCount(bulkSaleId));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      packageState?.IsBulkSale &&
      packageState?.Id &&
      userProfile?.data?.AgencyID
    ) {
      fetchData();
    }
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      packageId: packageState.Id,
      createDatetime: "",
      customerName: "",
      phoneNumber: "",
      email: "",
      isActive: true,
      taxNumber: "",
      taxOffice: "",
      city: "",
      district: "",
      address: "",
      customerId: 0,

      plate: "",
      usageType: "",
      modelYear: "",
      brand: "",
      model: "",

      paymentType: packageState.IsBulkSale ? 2 : 0,
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
    },

    validationSchema: Yup.object({
      createDatetime: Yup.string().required("Lütfen başlangıç tarihi girin"),
      customerName: Yup.string().required("Lütfen müşteri bilgisi girin"),

      phoneNumber: Yup.string()
        .required("Lütfen telefon numarası girin")
        .matches(phoneRegExp, "Lütfen geçerli bir telefon numarası girin"),
      email: Yup.string()
        .nullable()
        .email("Lütfen geçerli bir e-posta adresi girin")
        .notRequired(),
      taxNumber: Yup.string()
        .nullable()
        .notRequired()
        .test("is-valid-taxnumber", "VKN/TCKN geçersiz", (value) => {
          if (!value || value.trim() === "") return true;
          return /^[0-9]+$/.test(value);
        })
        .min(10, "VKN/TCKN en az 10 karakter olmalıdır")
        .max(11, "VKN/TCKN en fazla 11 karakter olmalıdır"),
      taxOffice: Yup.string(),
      city: Yup.string(),
      district: Yup.string(),
      address: Yup.string(),

      plate: Yup.string().required("Lütfen plaka giriniz"),
      usageType: Yup.string(),
      modelYear: Yup.number()
        .typeError("Model yılı girin")
        .required("Model yılı girin")
        .max(new Date().getFullYear(), `Geçersiz model yılı`),
      brand: Yup.string(),
      model: Yup.string(),

      cardHolderName: Yup.string().test(
        "card-required",
        "Lütfen ad soyad girin",
        function (value) {
          return this.parent.paymentType != 0 || !!value;
        }
      ),
      cardNumber: Yup.string()
        .matches(
          /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/,
          "Geçersiz kart numarası"
        )
        .test("card-required", "Lütfen kart numarası girin", function (value) {
          return this.parent.paymentType != 0 || !!value;
        }),
      expiryDate: Yup.string()
        .matches(
          /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
          "Geçersiz tarih formatı (AA/YY)"
        )
        .test(
          "expiry-required",
          "Lütfen son kullanma tarihini girin",
          function (value) {
            return this.parent.paymentType != 0 || !!value;
          }
        ),
      cvc: Yup.string()
        .matches(/^[0-9]{3}$/, "Geçersiz CVV")
        .test("cvc-required", "Lütfen CVV girin", function (value) {
          return this.parent.paymentType != 0 || !!value;
        }),
    }),

    onSubmit: async (values) => {
      const { cardHolderName, cardNumber, expiryDate, cvc } = values;
      const [expMonth, expYear] = expiryDate.split("/");
      const cardNumberEnd = cardNumber.replaceAll(" ", "");

      const paymentData = {
        ...values,
        cardHolderName,
        cardNumber: cardNumberEnd,
        expMonth: parseInt(expMonth, 10) || null,
        expYear: parseInt(expYear, 10) || null,
        cvc,
        installmentNumber: 1,
        description: "",
        posId: cardInfo.PosId || null,
        isSecure: cardInfo.IsSecure || false,
        agencyId: userProfile?.data?.AgencyID,
        bulkSaleId: packageState.IsBulkSale ? bulkSaleId : null,
        createDatetime: values.createDatetime
          ? (() => {
              const date = new Date(values.createDatetime);
              date.setHours(0, 0, 0, 0);
              date.setHours(date.getHours() + 3);
              return date.toISOString();
            })()
          : null,
      };

      try {
        const resultAction = await dispatch(createPayAgreement(paymentData));

        if (resultAction.meta.requestStatus === "fulfilled") {
          navigate("/agreements");
        } else if (resultAction.meta.requestStatus === "rejected") {
          const result = JSON.parse(resultAction.error.message);

          if (result.Code == 600) {
            navigate("/agreements");
          }
        }
      } catch (error) {
        console.error("Failed:", error);
      }
    },
  });

  const fetchCustomerFunc = async (customerId) => {
    try {
      const resultAction = await dispatch(fetchCustomer(customerId));

      if (resultAction.meta.requestStatus === "fulfilled") {
        validation.setValues({
          ...validation.values,
          customerName: resultAction.payload?.CustomerName || "",
          phoneNumber: resultAction.payload?.PhoneNumber || "",
          email: resultAction.payload?.Email || "",
          taxNumber: resultAction.payload?.TaxNumber || "",
          taxOffice: resultAction.payload?.TaxOffice || "",
          city: resultAction.payload?.City || "Adana",
          district: resultAction.payload?.District || "",
          address: resultAction.payload?.Address || "",
          customerId: resultAction.payload?.Id,
        });
      }
    } catch (error) {
      console.error("Failed:", error);
    }
  };

  const handleCustomerChange = (value) => {
    const customerId = value ? value.value : 0;
    validation.setFieldValue("customerId", customerId);

    if (customerId !== 0) {
      fetchCustomerFunc(customerId);
    } else {
      validation.setValues({
        ...validation.initialValues,
      });
    }
  };

  useEffect(() => {
    const cardNumber = validation.values.cardNumber.replaceAll(" ", "");

    if (cardNumber.length === 6 || cardNumber.length === 16) {
      try {
        dispatch(findBin(cardNumber.slice(0, 6)));
      } catch (error) {
        console.error("Failed:", error);
      }
    }
  }, [validation.values.cardNumber]);

  useEffect(() => {
    if (!validation.values.createDatetime) {
      const today = new Date();

      validation.setFieldValue("createDatetime", today.toISOString());
    }
  }, []);

  return (
    <Fragment>
      <Row>
        <Col lg={7}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
            autoComplete="off"
          >
            <Card>
              <CardBody>
                <h4 className="mt-4">Müşteri Bilgileri</h4>
                <hr className="mb-4 text-muted" />

                <Row className="align-items-start">
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="customerIdSelect" className="form-label">
                        Müşteri
                      </Label>
                      <Select
                        isClearable
                        placeholder="Müşteri seçiniz"
                        id="customerIdSelect"
                        name="customerId"
                        options={customers?.map((item) => ({
                          label: item.customerName,
                          value: item.id,
                        }))}
                        noOptionsMessage={() => "Seçenek bulunamadı"}
                        value={
                          customers
                            ?.map((item) => ({
                              label: item.customerName,
                              value: item.id,
                            }))
                            .find(
                              (item) =>
                                item.value === validation.values.customerId
                            ) || { label: "Müşteri seçiniz", value: 0 }
                        }
                        onChange={handleCustomerChange}
                        onBlur={validation.handleBlur}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              validation.touched.customerId &&
                              validation.errors.customerId &&
                              !state.isFocused
                                ? "#e30a17"
                                : "#e9ebec",
                          }),
                        }}
                      />
                      {validation.touched.customerId &&
                        validation.errors.customerId && (
                          <div className="d-block invalid-feedback">
                            {validation.errors.customerId}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label">Sözleşme Tarihi</Label>
                      <Flatpickr
                        className={
                          validation.touched.createDatetime &&
                          validation.errors.createDatetime
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        options={{
                          dateFormat: "d.m.Y",
                          minDate: "today",
                          maxDate: new Date().fp_incr(14),
                          locale: { ...Turkish },
                        }}
                        value={
                          validation.values.createDatetime
                            ? new Date(validation.values.createDatetime)
                            : new Date()
                        }
                        onChange={(date) => {
                          validation.setFieldValue(
                            "createDatetime",
                            date[0].toISOString()
                          );
                        }}
                      />
                      {validation.touched.createDatetime &&
                        validation.errors.createDatetime && (
                          <div
                            type="invalid"
                            className="d-block invalid-feedback"
                          >
                            {validation.errors.createDatetime}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="customerName" className="form-label">
                        Ad Soyad / Firma Ünvanı
                      </Label>
                      <Input
                        disabled={validation.values.customerId != 0}
                        name="customerName"
                        className="form-control"
                        placeholder="Ad Soyad / Firma Ünvanı"
                        type="text"
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.customerName || ""}
                        invalid={
                          validation.touched.customerName &&
                          validation.errors.customerName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.customerName &&
                        validation.errors.customerName && (
                          <FormFeedback type="invalid">
                            {validation.errors.customerName}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="phoneNumber">
                        Telefon Numarası
                      </Label>
                      <InputGroup>
                        <span
                          className="input-group-text "
                          style={{
                            width: "44px",
                            justifyContent: "center",
                          }}
                          id="inputGroupPrepend2"
                        >
                          0
                        </span>
                        <ReactInputMask
                          disabled={validation.values.customerId != 0}
                          mask="999 999 99 99"
                          maskChar=" "
                          name="phoneNumber"
                          placeholder="Telefon Numarası"
                          style={{ zIndex: 0 }}
                          className={
                            validation.touched.phoneNumber &&
                            validation.errors.phoneNumber
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={validation.handleChange}
                          value={validation.values.phoneNumber || ""}
                        />
                      </InputGroup>
                      {validation.touched.phoneNumber &&
                        validation.errors.phoneNumber && (
                          <div
                            type="invalid"
                            className="d-block invalid-feedback"
                          >
                            {validation.errors.phoneNumber}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="email" className="form-label">
                        E-posta Adresi
                      </Label>
                      <Input
                        disabled={validation.values.customerId != 0}
                        name="email"
                        className="form-control"
                        placeholder="Kullanıcı E-posta Adresi"
                        type="text"
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email && (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="taxNumber">
                        VKN/TCKN
                      </Label>
                      <InputGroup>
                        <input
                          name="taxNumber"
                          placeholder="VKN/TCKN"
                          inputMode="numeric"
                          type="text"
                          autoComplete="off"
                          maxLength={11}
                          className={
                            validation.touched.taxNumber &&
                            validation.errors.taxNumber
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");

                            validation.setFieldValue("taxNumber", value);
                          }}
                          value={validation.values.taxNumber || ""}
                        />
                      </InputGroup>
                      {validation.touched.taxNumber &&
                        validation.errors.taxNumber && (
                          <div
                            type="invalid"
                            className="d-block invalid-feedback"
                          >
                            {validation.errors.taxNumber}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="taxOffice" className="form-label">
                        Vergi Dairesi
                      </Label>
                      <Input
                        disabled={validation.values.customerId != 0}
                        name="taxOffice"
                        className="form-control"
                        placeholder="Vergi Dairesi"
                        type="text"
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.taxOffice || ""}
                        invalid={
                          validation.touched.taxOffice &&
                          validation.errors.taxOffice
                            ? true
                            : false
                        }
                      />
                      {validation.touched.taxOffice &&
                        validation.errors.taxOffice && (
                          <FormFeedback type="invalid">
                            {validation.errors.taxOffice}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="citySelect" className="form-label">
                        İl
                      </Label>
                      <Select
                        isDisabled={validation.values.customerId != 0}
                        placeholder="İl seçiniz"
                        id="citySelect"
                        name="city"
                        options={city}
                        noOptionsMessage={() => "Seçenek bulunamadı"}
                        isClearable={true}
                        value={city.find(
                          (item) => item.value === validation.values.city
                        )}
                        onChange={(value) => {
                          validation.setFieldValue(
                            "city",
                            value ? value.value : ""
                          );
                          validation.setFieldValue("district", "");
                        }}
                        onBlur={validation.handleBlur}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              validation.touched.city &&
                              validation.errors.city &&
                              !state.isFocused
                                ? "#e30a17"
                                : "#e9ebec",
                          }),
                        }}
                      />
                      {validation.touched.city && validation.errors.city && (
                        <div className="d-block invalid-feedback">
                          {validation.errors.city}
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="districtSelect" className="form-label">
                        İlçe
                      </Label>
                      <Select
                        isDisabled={
                          validation.values.customerId != 0 ||
                          !validation.values.city
                        }
                        placeholder="İlçe seçiniz"
                        id="districtSelect"
                        name="district"
                        options={
                          city.find(
                            (item) => item.value === validation.values.city
                          )?.districts || []
                        }
                        noOptionsMessage={() => "Seçenek bulunamadı"}
                        isClearable={true}
                        value={
                          city
                            .find(
                              (item) => item.value === validation.values.city
                            )
                            ?.districts.find(
                              (district) =>
                                district.value === validation.values.district
                            ) || ""
                        }
                        onChange={(value) => {
                          validation.setFieldValue(
                            "district",
                            value ? value.value : ""
                          );
                        }}
                        onBlur={validation.handleBlur}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              validation.touched.district &&
                              validation.errors.district &&
                              !state.isFocused
                                ? "#e30a17"
                                : "#e9ebec",
                          }),
                        }}
                      />
                      {validation.touched.district &&
                        validation.errors.district && (
                          <div className="d-block invalid-feedback">
                            {validation.errors.district}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="address" className="form-label">
                        Adres
                      </Label>
                      <Input
                        disabled={validation.values.customerId != 0}
                        name="address"
                        className="form-control"
                        placeholder="Mahalle, cadde, sokak..."
                        type="textarea"
                        autoComplete="off"
                        rows="2"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                          validation.touched.address &&
                          validation.errors.address
                            ? true
                            : false
                        }
                        style={{ resize: "none" }}
                      />
                      {validation.touched.address &&
                        validation.errors.address && (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h4 className="mt-4">Araç Bilgileri</h4>
                <hr className="mb-4 text-muted" />

                <Row className="align-items-start">
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="plate" className="form-label">
                        Plaka
                      </Label>
                      <Input
                        name="plate"
                        className="form-control"
                        placeholder="Plaka"
                        type="text"
                        autoComplete="off"
                        maxLength={50}
                        onChange={(e) => {
                          const value = e.target.value.toUpperCase();
                          validation.setFieldValue("plate", value);
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.plate || ""}
                        invalid={
                          validation.touched.plate && validation.errors.plate
                            ? true
                            : false
                        }
                      />
                      {validation.touched.plate && validation.errors.plate && (
                        <FormFeedback type="invalid">
                          {validation.errors.plate}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="usageTypeSelect" className="form-label">
                        Araç Tipi
                      </Label>
                      <Select
                        placeholder="Araç tipi seçiniz"
                        id="usageTypeSelect"
                        name="usageType"
                        options={carsInfo.map((item) => ({
                          value: item.typeName,
                          label: item.typeName,
                        }))}
                        noOptionsMessage={() => "Seçenek bulunamadı"}
                        isClearable={true}
                        value={carsInfo
                          .map((item) => ({
                            value: item.typeName,
                            label: item.typeName,
                          }))
                          .find(
                            (option) =>
                              option.value === validation.values.usageType
                          )}
                        onChange={(value) => {
                          validation.setFieldValue(
                            "usageType",
                            value ? value.value : ""
                          );
                          validation.setFieldValue("brand", ""); // Marka seçimini temizle
                        }}
                        onBlur={validation.handleBlur}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              validation.touched.usageType &&
                              validation.errors.usageType &&
                              !state.isFocused
                                ? "#e30a17"
                                : "#e9ebec",
                          }),
                        }}
                      />
                      {validation.touched.usageType &&
                        validation.errors.usageType && (
                          <div className="d-block invalid-feedback">
                            {validation.errors.usageType}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="modelYear">
                        Model Yılı
                      </Label>
                      <InputGroup>
                        <ReactInputMask
                          mask="9999"
                          maskChar=" "
                          name="modelYear"
                          alwaysShowMask
                          inputMode="numeric"
                          type="tel"
                          className={
                            validation.touched.modelYear &&
                            validation.errors.modelYear
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={validation.handleChange}
                          value={validation.values.modelYear}
                          style={{ zIndex: 0 }}
                        />
                      </InputGroup>
                      {validation.touched.modelYear &&
                        validation.errors.modelYear && (
                          <div
                            type="invalid"
                            className="d-block invalid-feedback"
                          >
                            {validation.errors.modelYear}
                          </div>
                        )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="brandSelect" className="form-label">
                        Marka
                      </Label>
                      <Select
                        placeholder="Marka seçiniz"
                        id="brandSelect"
                        name="brand"
                        options={
                          carsInfo
                            .find(
                              (item) =>
                                item.typeName === validation.values.usageType
                            )
                            ?.brands.map((brand) => ({
                              value: brand.brandName,
                              label: brand.brandName,
                            })) || []
                        }
                        noOptionsMessage={() => "Seçenek bulunamadı"}
                        isClearable={true}
                        value={
                          carsInfo
                            .find(
                              (item) =>
                                item.typeName === validation.values.usageType
                            )
                            ?.brands.map((brand) => ({
                              value: brand.brandName,
                              label: brand.brandName,
                            }))
                            .find(
                              (option) =>
                                option.value === validation.values.brand
                            ) || ""
                        }
                        onChange={(value) => {
                          validation.setFieldValue(
                            "brand",
                            value ? value.value : ""
                          );
                        }}
                        onBlur={validation.handleBlur}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              validation.touched.brand &&
                              validation.errors.brand &&
                              !state.isFocused
                                ? "#e30a17"
                                : "#e9ebec",
                          }),
                        }}
                      />
                      {validation.touched.brand && validation.errors.brand && (
                        <div className="d-block invalid-feedback">
                          {validation.errors.brand}
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="model" className="form-label">
                        Model
                      </Label>
                      <Input
                        name="model"
                        className="form-control"
                        placeholder="Model"
                        type="text"
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.model || ""}
                        invalid={
                          validation.touched.model && validation.errors.model
                            ? true
                            : false
                        }
                      />
                      {validation.touched.model && validation.errors.model && (
                        <FormFeedback type="invalid">
                          {validation.errors.model}
                        </FormFeedback>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h4 className="mt-4">Ödeme</h4>
                <hr className="mb-4 text-muted" />
                {packageState.IsBulkSale ? (
                  <Alert
                    color="success"
                    className="alert-border-left material-shadow"
                  >
                    <i className="ri-alert-line me-3 align-middle fs-16"></i>
                    Bu paket, <strong>ücretsiz</strong> olarak tanımlanacaktır!
                  </Alert>
                ) : (
                  <div>
                    <Row className="g-4 mb-4">
                      <Col sm={6}>
                        <div>
                          <div className="form-check card-radio">
                            <Input
                              id="paymentMethod01"
                              name="paymentType"
                              type="radio"
                              className="form-check-input"
                              value={0}
                              checked={validation.values.paymentType == 0}
                              onChange={(e) => {
                                validation.handleChange(e);

                                validation.setFieldValue("cardHolderName", "");
                                validation.setFieldValue("cardNumber", "");
                                validation.setFieldValue("expiryDate", "");
                                validation.setFieldValue("cvc", "");
                              }}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="paymentMethod01"
                            >
                              <span className="fs-16 text-muted me-2">
                                <i className="ri-bank-card-fill align-bottom"></i>
                              </span>
                              <span className="fs-12 text-wrap">
                                Kredi / Banka Kartı
                              </span>
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div>
                          <div className="form-check card-radio">
                            <Input
                              id="paymentMethod02"
                              name="paymentType"
                              type="radio"
                              className="form-check-input"
                              value={1}
                              checked={validation.values.paymentType == 1}
                              onChange={(e) => {
                                validation.handleChange(e);

                                validation.setFieldValue("cardHolderName", "");
                                validation.setFieldValue("cardNumber", "");
                                validation.setFieldValue("expiryDate", "");
                                validation.setFieldValue("cvc", "");
                              }}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="paymentMethod02"
                            >
                              <span className="fs-16 text-muted me-2">
                                <i className="ri-send-plane-line align-bottom"></i>
                              </span>
                              <span className="fs-12 text-wrap">
                                Havale / EFT
                              </span>
                            </Label>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {validation.values.paymentType == 0 ? (
                      <Row className="g-2 mb-2">
                        <Col lg={12}>
                          <div>
                            <Label
                              htmlFor="cardHolderName"
                              className="form-label"
                            >
                              Ad Soyad
                            </Label>
                            <Input
                              id="cardHolderName"
                              name="cardHolderName"
                              value={validation.values.cardHolderName}
                              type="text"
                              className="form-control pe-5"
                              placeholder="Kart Sahibi Adı Soyadı"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.cardHolderName &&
                                validation.errors.cardHolderName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.cardHolderName &&
                              validation.errors.cardHolderName && (
                                <FormFeedback type="invalid">
                                  {validation.errors.cardHolderName}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div>
                            <Label className="form-label" htmlFor="cardNumber">
                              Kart Numarası
                            </Label>
                            <InputGroup>
                              <ReactInputMask
                                mask="9999 9999 9999 9999"
                                maskChar=""
                                name="cardNumber"
                                alwaysShowMask={false}
                                inputMode="numeric"
                                type="tel"
                                className={
                                  validation.touched.cardNumber &&
                                  validation.errors.cardNumber
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                placeholder="Kart Numarası"
                                onChange={validation.handleChange}
                                value={validation.values.cardNumber}
                                style={{ zIndex: 0 }}
                              />
                            </InputGroup>
                            {validation.touched.cardNumber &&
                              validation.errors.cardNumber && (
                                <div
                                  type="invalid"
                                  className="d-block invalid-feedback"
                                >
                                  {validation.errors.cardNumber}
                                </div>
                              )}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="expiryDate">
                              Son Kullanma Tarihi
                            </Label>
                            <ReactInputMask
                              mask="99/99"
                              maskChar=" "
                              name="expiryDate"
                              inputMode="numeric"
                              className={
                                validation.touched.expiryDate &&
                                validation.errors.expiryDate
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="AA/YY"
                              onChange={validation.handleChange}
                              value={validation.values.expiryDate}
                              style={{ zIndex: 0 }}
                            />
                            {validation.touched.expiryDate &&
                              validation.errors.expiryDate && (
                                <div
                                  type="invalid"
                                  className="d-block invalid-feedback"
                                >
                                  {validation.errors.expiryDate}
                                </div>
                              )}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="cvc">
                              CVV
                            </Label>
                            <Input
                              id="cvc"
                              name="cvc"
                              inputMode="numeric"
                              maxLength="3"
                              className={
                                validation.touched.cvc && validation.errors.cvc
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="CVV" // Placeholder eklendi
                              onChange={validation.handleChange}
                              value={validation.values.cvc}
                            />
                            {validation.touched.cvc &&
                              validation.errors.cvc && (
                                <div
                                  type="invalid"
                                  className="d-block invalid-feedback"
                                >
                                  {validation.errors.cvc}
                                </div>
                              )}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <div className="sticky-side-div mb-4 mt-2">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <th>
                                  <div className="d-flex flex-column">
                                    <span>BANKA</span>
                                  </div>
                                </th>
                                <td className="text-end">
                                  <span className="fw-semibold" id="bank">
                                    Türkiye İş Bankası
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex flex-column">
                                    <span>AD SOYAD</span>
                                  </div>
                                </th>
                                <td className="text-end">
                                  <span
                                    className="fw-semibold"
                                    id="nameSurname"
                                  >
                                    GLOBAL TAŞIT YOL YARDIM LİMİTED ŞİRKETİ
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <div className="d-flex flex-column">
                                    <span>IBAN</span>
                                  </div>
                                </th>
                                <td className="text-end d-flex justify-content-end align-items-center">
                                  <span className="fw-semibold" id="iban">
                                    TR47 0006 4000 0015 3140 2369 06
                                  </span>
                                  <div
                                    onClick={() => {
                                      const ibanText =
                                        "TR47 0006 4000 0015 3140 2369 06";
                                      const textArea =
                                        document.createElement("textarea");
                                      textArea.value = ibanText;
                                      document.body.appendChild(textArea);
                                      textArea.select();
                                      document.execCommand("copy");
                                      document.body.removeChild(textArea);

                                      toast.success("IBAN Kopyalandı", {
                                        position: "top-center",
                                        isLoading: false,
                                        autoClose: 5000,
                                        transition: Slide,
                                        hideProgressBar: true,
                                      });
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "0.3rem",
                                    }}
                                  >
                                    <i className="ri-file-copy-line align-middle me-2 fs-14 text-dark"></i>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="hstack gap-2 justify-content-end mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      validation.handleSubmit();
                    }}
                  >
                    Satın Al
                  </button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </Col>
        <Col lg={5}>
          <Card>
            <CardBody>
              <h4 className="mt-4">Paket Detayları</h4>
              <hr className="mb-4 text-muted" />
              <div className="sticky-side-div mb-4 mt-2">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      {packageState.IsBulkSale && (
                        <tr className="table-active">
                          <th>Kalan Paket:</th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {bulkPackageCount} Adet
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.TotalLimit > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Sözleşmeyi Kapsayan 1 Yıllık Tutar:</span>
                              <span className="fw-light">
                                {packageState.TotalLimitDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.TotalLimit}
                            </span>
                          </td>
                        </tr>
                      )}

                      {packageState.Accident > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Kaza Durumunda:</span>
                              <span className="fw-light">
                                {packageState.AccidentDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.Accident}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.Fault > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Arıza Durumunda:</span>
                              <span className="fw-light">
                                {packageState.FaultDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.Fault}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.Tire > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Lastik Patlamasında:</span>
                              <span className="fw-light">
                                {packageState.TireDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.Tire}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.Fuel > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Yakıt Bitmesinde:</span>
                              <span className="fw-light">
                                {packageState.FuelDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.Fuel}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.Windshield > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Ön Cam Patlaması/Kırılması Durumunda:</span>
                              <span className="fw-light">
                                {packageState.WindshieldDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.Windshield}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.Landslide > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Heyelan Durumunda:</span>
                              <span className="fw-light">
                                {packageState.LandslideDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.Landslide}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.Headlight > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Far Arıza Durumunda:</span>
                              <span className="fw-light">
                                {packageState.HeadlightDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.Headlight}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.Health > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>Sağlık Sorunları Durumunda:</span>
                              <span className="fw-light">
                                {packageState.HealthDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.Health}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.Tired > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>
                                Yorgunluktan Kaynaklı Tek Sürücülü Araçlar İçin:
                              </span>
                              <span className="fw-light">
                                {packageState.TiredDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.Tired}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.EVFault > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>
                                Elektrikli Araçların Yolda Kalması Durumunda:
                              </span>
                              <span className="fw-light">
                                {packageState.EVFaultDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.EVFault}
                            </span>
                          </td>
                        </tr>
                      )}
                      {packageState.WildAnimal > 0 && (
                        <tr>
                          <th>
                            <div className="d-flex flex-column">
                              <span>
                                Yabani Hayvanların Aracı Hareket Edemez Hale
                                Getirdiği Durumunda:
                              </span>
                              <span className="fw-light">
                                {packageState.WildAnimalDesc}
                              </span>
                            </div>
                          </th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {packageState.WildAnimal}
                            </span>
                          </td>
                        </tr>
                      )}
                      <tr className="table-active">
                        <th>Fiyat:</th>
                        <td className="text-end">
                          <span className="fw-semibold" id="cart-total">
                            {new Intl.NumberFormat("tr-TR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(packageState.Price)}{" "}
                            TL
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Checkout;
