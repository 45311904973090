import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  package: {},
  packageLoading: true,
  packageRefresh: true,

  packages: [],
  packagesLoading: true,
  packagesRefresh: true,

  allPackages: [],
  allPackagesLoading: true,
  allPackagesRefresh: true,

  bulkPackages: [],
  bulkPackagesLoading: true,
  bulkPackagesRefresh: true,

  bulkPackageCount: 0,
  bulkPackageCountLoading: true,
  bulkPackageCountRefresh: true,

  createPackageLoading: false,
  updatePackageLoading: false,
  deletePackageLoading: false,
  setActivationLoading: false,
  payApproveLoading: false,
};

export const fetchPackage = createAsyncThunk(
  "package/fetchPackage",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    try {
      const response = await baseApi.get(`/Package/${id}`);

      return response.data.Value;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchPackages = createAsyncThunk(
  "package/fetchPackages",
  async () => {
    try {
      const response = await baseApi.get(`/Package`);

      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description ||
          error.response?.status ||
          error.response?.data?.errorMessage
      );
    }
  }
);

export const fetchAllPackages = createAsyncThunk(
  "package/fetchAllPackages",
  async () => {
    try {
      const response = await baseApi.get(`/Package/GetAllPackages`);

      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description ||
          error.response?.status ||
          error.response?.data?.errorMessage
      );
    }
  }
);

export const fetchBulkPackages = createAsyncThunk(
  "package/fetchBulkPackages",
  async () => {
    try {
      const response = await baseApi.get(`/Package/GetBulkPackage`);

      return response.data.Value;
    } catch (error) {
      throw new Error(
        error.response?.data?.Error?.Description ||
          error.response?.status ||
          error.response?.data?.errorMessage
      );
    }
  }
);

export const createPackage = createAsyncThunk(
  "package/createPackage",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: 0,
      packageName: values.packageName,
      price: values.price,
      totalLimit: values.totalLimit,
      totalLimitDesc: values.totalLimitDesc,
      towingLimit: values.towingLimit,
      towingLimitDesc: values.towingLimitDesc,
      windshield: values.windshield,
      windshieldDesc: values.windshieldDesc,
      accident: values.accident,
      accidentDesc: values.accidentDesc,
      fault: values.fault,
      faultDesc: values.faultDesc,
      tire: values.tire,
      tireDesc: values.tireDesc,
      fuel: values.fuel,
      fuelDesc: values.fuelDesc,
      landslide: values.landslide,
      landslideDesc: values.landslideDesc,
      headlight: values.headlight,
      headlightDesc: values.headlightDesc,
      health: values.health,
      healthDesc: values.healthDesc,
      tired: values.tired,
      tiredDesc: values.tiredDesc,
      evFault: values.evFault,
      evFaultDesc: values.evFaultDesc,
      wildAnimal: values.wildAnimal,
      wildAnimalDesc: values.wildAnimalDesc,
      description: values.description,
      agreement: values.agreement,
      picturePath: values.picturePath,
      isActive: true,
      isBulkSale: values.isBulkSale,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Package`, data);

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response?.data?.Error?.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const updatePackage = createAsyncThunk(
  "package/updatePackage",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      id: values.id,
      packageName: values.packageName,
      price: values.price,

      totalLimit: values.totalLimit,
      totalLimitDesc: values.totalLimitDesc,
      towingLimit: values.towingLimit,
      towingLimitDesc: values.towingLimitDesc,
      windshield: values.windshield,
      windshieldDesc: values.windshieldDesc,
      accident: values.accident,
      accidentDesc: values.accidentDesc,
      fault: values.fault,
      faultDesc: values.faultDesc,
      tire: values.tire,
      tireDesc: values.tireDesc,
      fuel: values.fuel,
      fuelDesc: values.fuelDesc,
      landslide: values.landslide,
      landslideDesc: values.landslideDesc,
      headlight: values.headlight,
      headlightDesc: values.headlightDesc,
      health: values.health,
      healthDesc: values.healthDesc,
      tired: values.tired,
      tiredDesc: values.tiredDesc,
      evFault: values.evFault,
      evFaultDesc: values.evFaultDesc,
      wildAnimal: values.wildAnimal,
      wildAnimalDesc: values.wildAnimalDesc,
      description: values.description,
      agreement: values.agreement,
      picturePath: values.picturePath,
      isActive: values.isActive,
      isBulkSale: values.isBulkSale,
    };

    const loadingToast = toast.loading("Kaydediliyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.put(`/Package/${values.id}`, data);

      toast.dismiss();

      return response.data;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response?.data?.Error?.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const saleBulkPackage = createAsyncThunk(
  "package/saleBulkPackage",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const data = {
      packageId: values.packageId,
      agencyId: values.agencyId,
      quantity: values.quantity,
      unitPrice: values.unitPrice,
      creditCardInfo: {
        cardNumber: values.cardNumber,
        cardHolderName: values.cardHolderName,
        expireMonth: values.expireMonth,
        expireYear: values.expireYear,
        cvc: values.cvc,
        posId: values.posId,
        isSecure: values.isSecure,
        appType: "app",
        paymentType: parseInt(values.paymentType),
      },
    };

    const loadingToast = toast.loading("Lütfen bekleyin!", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.post(`/Package/SaleBulkPackage`, data);

      if (parseInt(values.paymentType) == 0) {
        if (values.isSecure) {
          document.open();
          document.write(response.data.Value);
          document.close();
        } else {
          window.location.href = response.data.Value;
        }
      }

      toast.dismiss(loadingToast);
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        JSON.stringify(error.response?.data?.Error) || error.response?.status
      );
    }
  }
);

export const setActivation = createAsyncThunk(
  "package/setActivation",
  async (values) => {
    if (!values) throw new Error("Veri bulunamadı!");

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    const data = {
      packageAgencyId: values.packageAgencyId,
      isActive: values.isActive,
    };

    try {
      const response = await baseApi.post(`/Package/SetActivation`, data);

      toast.update(loadingToast, {
        render: response.data.Value,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const fetchBulkPackageCount = createAsyncThunk(
  "package/fetchBulkPackageCount",
  async (bulkSaleId) => {
    if (!bulkSaleId) {
      throw new Error("Veri bulunamadı!");
    }

    try {
      const response = await baseApi.post(
        `/Package/GetBulkPackageCount?bulkSaleId=${bulkSaleId}`
      );

      return response.data.Value;
    } catch (error) {
      toast.error("Hata: " + error.response?.data?.Error?.Description, {
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

export const payApprove = createAsyncThunk(
  "package/payApprove",
  async (values) => {
    if (!values) throw new Error("Veri bulunamadı!");

    const loadingToast = toast.loading("Lütfen bekleyin...", {
      position: "top-center",
      transition: Slide,
    });

    const data = {
      packageAgencyId: values.packageAgencyId,
      isPaid: true,
    };

    try {
      const response = await baseApi.post(
        `/Package/SetBulkSalePaymentStatus`,
        data
      );

      toast.update(loadingToast, {
        render: response.data.Value,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      return response.data.Value;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.Error.Description,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(
        error.response?.data?.Error?.Description || error.response?.status
      );
    }
  }
);

// pasif
export const deletePackage = createAsyncThunk(
  "package/deletePackage",
  async (id) => {
    if (!id) throw new Error("Id bulunamadı!");

    const loadingToast = toast.loading("Siliniyor...", {
      position: "top-center",
      transition: Slide,
    });

    try {
      const response = await baseApi.delete(`/Package/${id}`);

      toast.dismiss();

      return response.data.result;
    } catch (error) {
      toast.update(loadingToast, {
        render: "Hata: " + error.response.data.errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        transition: Slide,
        hideProgressBar: true,
      });

      throw new Error(error.response.data.errorMessage);
    }
  }
);

export const packageSlice = createSlice({
  name: "package",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackage.pending, (state, action) => {
        state.package = {};
        state.packageLoading = true;
      })
      .addCase(fetchPackage.fulfilled, (state, action) => {
        state.package = action.payload;
        state.packageLoading = false;
      })
      .addCase(fetchPackage.rejected, (state, action) => {
        state.package = {};
        state.packageLoading = false;
      });

    builder
      .addCase(fetchPackages.pending, (state, action) => {
        state.packages = [];
        state.packagesLoading = true;
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.packages = action.payload;
        state.packagesLoading = false;
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.packages = [];
        state.packagesLoading = false;
      });

    builder
      .addCase(fetchAllPackages.pending, (state, action) => {
        state.allPackages = [];
        state.allPackagesLoading = true;
      })
      .addCase(fetchAllPackages.fulfilled, (state, action) => {
        state.allPackages = action.payload;
        state.allPackagesLoading = false;
      })
      .addCase(fetchAllPackages.rejected, (state, action) => {
        state.allPackages = [];
        state.allPackagesLoading = false;
      });

    builder
      .addCase(fetchBulkPackages.pending, (state, action) => {
        state.bulkPackages = [];
        state.bulkPackagesLoading = true;
      })
      .addCase(fetchBulkPackages.fulfilled, (state, action) => {
        state.bulkPackages = action.payload;
        state.bulkPackagesLoading = false;
      })
      .addCase(fetchBulkPackages.rejected, (state, action) => {
        state.bulkPackages = [];
        state.bulkPackagesLoading = false;
      });

    builder
      .addCase(createPackage.pending, (state, action) => {
        state.createPackageLoading = true;
      })
      .addCase(createPackage.fulfilled, (state, action) => {
        state.createPackageLoading = false;
        state.packagesRefresh = !state.packagesRefresh;
        state.allPackagesRefresh = !state.allPackagesRefresh;
      })
      .addCase(createPackage.rejected, (state, action) => {
        state.createPackageLoading = false;
      });

    builder
      .addCase(updatePackage.pending, (state, action) => {
        state.updatePackageLoading = true;
        state.packagesLoading = true;
      })
      .addCase(updatePackage.fulfilled, (state, action) => {
        state.updatePackageLoading = false;
        state.packagesRefresh = !state.packagesRefresh;
        state.allPackagesRefresh = !state.allPackagesRefresh;
      })
      .addCase(updatePackage.rejected, (state, action) => {
        state.updatePackageLoading = false;
      });

    builder
      .addCase(deletePackage.pending, (state, action) => {
        state.deletePackageLoading = true;
      })
      .addCase(deletePackage.fulfilled, (state, action) => {
        state.deletePackageLoading = false;
        state.packagesRefresh = !state.packagesRefresh;
        state.allPackagesRefresh = !state.allPackagesRefresh;
      })
      .addCase(deletePackage.rejected, (state, action) => {
        state.deletePackageLoading = false;
      });

    builder
      .addCase(setActivation.pending, (state, action) => {
        state.setActivationLoading = true;
      })
      .addCase(setActivation.fulfilled, (state, action) => {
        state.setActivationLoading = false;
        state.bulkPackagesRefresh = !state.bulkPackagesRefresh;
      })
      .addCase(setActivation.rejected, (state, action) => {
        state.setActivationLoading = false;
      });

    builder
      .addCase(payApprove.pending, (state, action) => {
        state.payApproveLoading = true;
      })
      .addCase(payApprove.fulfilled, (state, action) => {
        state.payApproveLoading = false;
        state.bulkPackagesRefresh = !state.bulkPackagesRefresh;
      })
      .addCase(payApprove.rejected, (state, action) => {
        state.payApproveLoading = false;
      });

    builder
      .addCase(fetchBulkPackageCount.pending, (state, action) => {
        state.bulkPackageCount = 0;
        state.bulkPackageCountLoading = true;
      })
      .addCase(fetchBulkPackageCount.fulfilled, (state, action) => {
        state.bulkPackageCount = action.payload;
        state.bulkPackageCountLoading = false;
      })
      .addCase(fetchBulkPackageCount.rejected, (state, action) => {
        state.bulkPackageCount = 0;
        state.bulkPackageCountLoading = false;
      });
  },
});

export default packageSlice.reducer;
